@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700&display=swap");

body{
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  outline: none;
  background-image: url("image1.jpg");
}

.movie-container{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
}

.movie{
  width : 250px;
  margin: 1rem;
}
.movie img{
  max-width: 100%;
}

/* h2{
  background-color: antiquewhite;
} */